import {LitElement, html} from '@polymer/lit-element';

export class ShadyFigure extends LitElement {

  static get is() { return 'shady-figure'; }

  render() {
    return html`
      <style>
        * {
          box-sizing: border-box;
        }
        figure ::slotted(iframe) {
          width: 100%;
          height: 100%;
          border: 0;
          margin: 0;
          padding: 0;
        }

        figure ::slotted(picture) {
          display: block;
        }
        figure ::slotted(figcaption) {
          display: block;
          max-width: 100%;
          color: #fff;
          text-shadow: rgba(0, 0, 0, 0.5) 0 1px;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
        }
      </style>
      <figure>
        <slot></slot>
      </figure>
    `;
  }
}

window.customElements.define(ShadyFigure.is, ShadyFigure);
