import {domReady} from './utils';
import {ShadyBoxes} from './components/shady-boxes';
import {ShadyBox} from './components/shady-box';
import {ShadyFigure} from './components/shady-figure';
import {ResponsiveContainer} from './components/responsive-container';

function main() {
  domReady(() => {
    document.body.addEventListener('click', (event) => {
      const target = event.target.closest('a');
      if (!target) {
        return;
      }
      const href = target.getAttribute('href');
      const match = document.body.querySelector(
        `iframe[data-src^="${href}"],iframe[src^="${href}"],img[src^="${href}"],img[data-src^="${href}"]`);
      if (match) {
        const figure = match.closest('shady-figure');
        const carousel = match.closest('shady-box');
        if (figure && carousel) {
          event.preventDefault();
          carousel.open(figure);
        }
      }
    });
  });
}

main();
