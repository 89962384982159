import {LitElement, html} from '@polymer/lit-element';

export class ShadyBoxes extends LitElement {

  static get is() { return 'shady-boxes'; }

  render() {
    return html`
      <style>
        * {
          box-sizing: border-box;
        }
        :host {
          display: none;
          position: fixed;
          z-index: 200;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          width: 100%;
          height: 100%;
        }
      </style>
      <slot></slot>
    `;
  }
}

window.customElements.define(ShadyBoxes.is, ShadyBoxes);
